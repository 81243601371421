export interface ElasticSearchVariant {
	amount: number;
	sale_amount: number | null;
	currency_decimal_digits: number;
	currency_template: string;
	manage_inventory: boolean;
	inventory_quantity: number;
	currency_code: string;
	id: string;
}
export interface ElasticSearchResult {
	id: string;
	title: string;
	thumbnail: string;
	site_product_selection: string | null;
	variants: Array<ElasticSearchVariant>;
}

export interface SiteElementSearchItem {
	id: string;
	thumbnail: string;
	title: string;
	href: string;
	price: string;
	oldPrice: string | null;
	isInStock: boolean;
}

export interface EcommerceProductOptionValue {
	id: string;
	option_id: string;
	value: string;
	variant_id: string;
}
export interface EcommerceProductOption {
	id: string;
	title: string;
	value: EcommerceProductOptionValue;
	values: Array<EcommerceProductOptionValue>;
}

export interface EcommerceBookingEvent {
	id: string;
	buffer_time_after: number | null;
	buffer_time_before: number | null;
	length: number;
	length_unit: string;
	location: string;
	minimum_booking_notice: number | null;
	minimum_booking_notice_unit: string;
	schedule_id: string;
}
export interface EcommerceCurrency {
	code: string;
	decimal_digits: number;
	min_amount: number;
	name: string;
	name_plural: string;
	rounding: number;
	symbol: string;
	symbol_native: string;
	template: string;
}
export interface EcommerceVariantPrice {
	amount: number;
	currency_code: string;
	sale_amount: number | null;
	currency: EcommerceCurrency
}
export interface EcommerceProductVariant {
	id: string;
	image_url?: string;
	manage_inventory: boolean;
	options: Array<EcommerceProductOptionValue> | [];
	sku?: string;
	booking_event?: EcommerceBookingEvent,
	prices: Array<EcommerceVariantPrice> | [];
}

export interface EcommerceProductVariantQuantity {
	id: string;
	inventory_quantity: number;
}
export interface EcommerceProductAdditionalInfo {
	id: string;
	title: string;
	description: string;
	order: number;
}

export interface EcommerceProductImage {
	order: number;
	url: string;
	type: string;
}
export interface EcommerceProduct {
	id: string | number;
	description: string;
	images: EcommerceProductImage[] | [];
	options: EcommerceProductOption[] | [];
	product_collections: Array<any>;
	ribbon_text: string | null;
	site_product_selection: string | null;
	subtitle: string | null;
	thumbnail: string | null;
	title: string;
	type: {
		value: string;
	};
	variants: EcommerceProductVariant[];
	purchasable?: boolean;
	additional_info: EcommerceProductAdditionalInfo[];
}

export interface EcommerceProductPage {
	productId: string;
	slug: string;
}

export interface EcommerceCollection {
	id: string;
	title: string;
	created_at: string;
	updated_at: string;
	delete_at: string | null;
	image_url: string | null;
	metadata: Record<string, any> | null;
	store_id: string;
}

export interface SiteLanguageSwitcherLanguage {
	country: string;
	flagPath: string;
	href: string;
	isHidden: boolean;
	locale: string;
	name: string;
}

export interface SiteBlockHeaderItem {
	id: string;
	name: string;
	url: string;
	isCurrent: boolean;
	href: string;
	target: string;
	rel: string;
	hasDropdown: boolean;
	subItems: SiteBlockHeaderItem[];
}

export interface GridSocialIconLink {
	link: string;
	svg: string;
	title: string;
}

export enum SortingOptions {
	DEFAULT = 'default',
	PRICE_LTH = 'priceLTH',
	PRICE_HTL = 'priceHTL',
	MOST_RECENT = 'mostRecent',
}

export interface EcommerceProductSortingOption {
	id: SortingOptions;
	isChecked: boolean;
	value: string;
	label: string;
}

export interface EcommerceProductSorting {
	enabled: boolean;
	textColor?: string;
	sortingOptions?: EcommerceProductSortingOption[];
}

export enum ImageRatioOption {
	CONTAIN = 'contain',
	COVER = 'cover',
	LANDSCAPE = 'landscape',
	PORTRAIT = 'portrait',
}

export enum ImageHoverEffect {
	NO_EFFECT = 'no_effect',
	ZOOM = 'zoom',
	SWAP_IMAGE = 'swap_image'
}
